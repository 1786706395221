import {getAppHeight} from "./_func/_jq/getAppHeight";
import {getScrollWidth} from "./_func/_jq/getScrollWidth";
import { Calendar } from "fullcalendar";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
/* ------------------- */


document.addEventListener('DOMContentLoaded', () => {
    // app height
    getAppHeight();
    // scroll width
    getScrollWidth();
    // menu function
    $('.main-menu__item.submenu-item .main-menu__link').click(function (){
        $(this).toggleClass('active');
    });
    // calendar
    const calendarEl = document.getElementById('calendar');
    if(calendarEl !== null){
        const calendar = new Calendar(calendarEl, {
            plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin],
            locale: 'ru',
            headerToolbar: {
                start: 'prev, next, title',
                center: '',
                end: 'today'
            },
            buttonText: {
                today: 'На сегодня',
                month: 'Месяц',
                day: 'День'
            },
            firstDay: 1,
            height: 913
        })
        calendar.render();




        /* tmp content */
        $('td[aria-labelledby="fc-dom-12"]').find('.fc-daygrid-day-events').append('<div class="event-item">\n' +
            '                <div class="event-item__time">11:00 - 12:00</div>\n' +
            '                <div class="event-item__descr">\n' +
            '                    <div class="event-name">ISF 520</div>\n' +
            '                    <div class="event-descr">\n' +
            '                        <div class="descr-title">Курс</div>\n' +
            '                        <div class="descr-txt">\n' +
            '                            <p>«Международные стандарты и сертификаты»</p>\n' +
            '                        </div>\n' +
            '                        <div class="descr-title">Дата</div>\n' +
            '                        <div class="descr-txt">\n' +
            '                            <p>Пятница, 30.06. 12:00-21:00</p>\n' +
            '                        </div>\n' +
            '                        <div class="descr-title">Преподаватели</div>\n' +
            '                        <div class="descr-txt">\n' +
            '                            <p>Иванов Иван Иванович</p>\n' +
            '                            <p>Сергеев Сергей Сергеевич</p>\n' +
            '                            <p>Петров Петр Петрович</p>\n' +
            '                        </div>\n' +
            '                        <div class="descr-title">Количество слушателей</div>\n' +
            '                        <div class="descr-txt">\n' +
            '                            <p>123 чел.</p>\n' +
            '                        </div>\n' +
            '                    </div>\n' +
            '                </div>\n' +
            '            </div>\n' +
            '            <div class="event-item">\n' +
            '                <div class="event-item__time">11:00 - 12:00</div>\n' +
            '                <div class="event-item__descr">\n' +
            '                    <div class="event-name">ISF 522</div>\n' +
            '                    <div class="event-descr">\n' +
            '                        <div class="descr-title">Курс</div>\n' +
            '                        <div class="descr-txt">\n' +
            '                            <p>«Международные стандарты и сертификаты»</p>\n' +
            '                        </div>\n' +
            '                        <div class="descr-title">Дата</div>\n' +
            '                        <div class="descr-txt">\n' +
            '                            <p>Пятница, 30.06. 12:00-21:00</p>\n' +
            '                        </div>\n' +
            '                        <div class="descr-title">Преподаватели</div>\n' +
            '                        <div class="descr-txt">\n' +
            '                            <p>Иванов Иван Иванович</p>\n' +
            '                            <p>Сергеев Сергей Сергеевич</p>\n' +
            '                            <p>Петров Петр Петрович</p>\n' +
            '                        </div>\n' +
            '                        <div class="descr-title">Количество слушателей</div>\n' +
            '                        <div class="descr-txt">\n' +
            '                            <p>123 чел.</p>\n' +
            '                        </div>\n' +
            '                    </div>\n' +
            '                </div>\n' +
            '            </div>\n' +
            '            <a href="javascript:void(0);" class="see-more-link">Смотреть больше</a>\n' +
            '            <div class="events-list">\n' +
            '                <div class="events-list__top-row">\n' +
            '                    <span class="date">22 июня, 2023</span>\n' +
            '                    <a href="javascript:void(0);" class="close-link"><i class="icon icon-close"></i></a>\n' +
            '                </div>\n' +
            '                <div class="events-list__item">\n' +
            '                    <div class="event-time">11:00 - 12:00</div>\n' +
            '                    <div class="event-name"><a href="#" class="event-link">ISF 520</a></div>\n' +
            '                </div>\n' +
            '                <div class="events-list__item">\n' +
            '                    <div class="event-time">11:00 - 12:00</div>\n' +
            '                    <div class="event-name"><a href="#" class="event-link">ISF 520</a></div>\n' +
            '                </div>\n' +
            '                <div class="events-list__item">\n' +
            '                    <div class="event-time">11:00 - 12:00</div>\n' +
            '                    <div class="event-name"><a href="#" class="event-link">ISF 520</a></div>\n' +
            '                </div>\n' +
            '                <div class="events-list__item">\n' +
            '                    <div class="event-time">11:00 - 12:00</div>\n' +
            '                    <div class="event-name"><a href="#" class="event-link">ISF 520</a></div>\n' +
            '                </div>\n' +
            '            </div>');

        // show / hide functions
        $('#calendar .see-more-link').click(function (){
            $(this).siblings('.events-list').show();
        });
        $('#calendar .events-list .close-link').click(function (){
            $(this).parents('.events-list').hide();
        })
    }
})